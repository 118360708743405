(function (angular, _, undefined) {
    "use strict";
    var projects = angular.module("mikesProjects");

    projects.controller("mikesProjects.ctrl", ["$scope", "$http", "$filter", "$localStorage", "ProjectService",
        function ($scope, $http, $filter, $localStorage, Projects) {
        var vm = this;

        if (!($localStorage.projects instanceof Array)) {
            $localStorage.projects = [];
        }

        vm.dateOptions = {
            year: "numeric",
            month: "short",
            day: "numeric"
        };

        vm.bumpTarget = function (idx) {
            vm.cards[idx].target += "_";
        };

        vm.cards = $localStorage.projects;

        Projects.getProjects()
            .then(function () {
                vm.cards = $localStorage.projects;
            }, function (err) {
                console.log("Error: " + err);
            });
    }]);

})(angular, _);
